import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Deload Week`}</em></p>
    <p>{`Pistols 12-12-12 (6/leg) weighted if possible`}</p>
    <p>{`DB Stiff Leg Deadlifts 12-12-12`}</p>
    <p>{`then,`}</p>
    <p>{`10-1 reps each of:`}</p>
    <p>{`Ball Slams (30/20`}{`#`}{`)`}</p>
    <p>{`DB Step Ups (40/25’s, 24/20″)`}</p>
    <p>{`T2B`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`The sign up sheet for Memorial Day Murph is up next to the front
white board.  We have 15 slots each half hour starting at 10:30am (10:30
& 11:00 slots are filled) on the 28th so sign up ahead of time!  If you
are a visitor and won’t be in before Murph you can email us and we’ll
sign you up.  All other class times and open gym are cancelled for the
day.`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`The Central Regionals start this Friday.  Check out the action at
Games.CrossFit.com.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      